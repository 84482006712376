<template>
  <div style="text-align: left; font-size: 12px; display: flex;">
        <div style="flex: 1;">
            <span class="el-icon-s-fold" style="font-size: 25px;" @click="openCollaspse"></span>
        </div>

        <el-dropdown @command="handleCommand">
            <div>
                <span>管理员</span>
                <i class="el-icon-arrow-down" style="font-size: 15px; margin-right: 15px; margin-left: 10px;"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="person">个人信息</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            <!-- <el-dropdown-item>删除</el-dropdown-item> -->
            </el-dropdown-menu>
        </el-dropdown>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如：import 《组件名称》 from '《组件路径》';
export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    openCollaspse: Function
  },
  data() {
//这里存放数据
    return {
    };
  },
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  handleCommand(command){
    switch (command) {
      case 'person':
      this.$router.push("/person")
        break;
      case 'logout':
        this.logout();
        break;
    
      default:
        break;
    }
  },
  logout() {
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    this.$router.push("/login")
  }
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() { },
beforeMount() { }, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() { },
beforeUpdate() { }, //生命周期 - 更新之前
updated() { }, //生命周期 - 更新之后
beforeDestroy() { }, //生命周期 - 销毁之前
destroyed() { }, //生命周期 - 销毁完成
activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style>
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
</style>