<template>
  <div>
        <div class="header-logo">
            <img class="logo" src="@/assets/logo.png"/>
            <span class="text" v-show="isLogoTextShow">后台管理</span>
        </div>

        <el-menu :default-openeds="['1', '1']" 
        active-text-color="#2d8cf0" 
        text-color="#fff"
        :collapse="isCollapse"
        router>

        <el-submenu index="1" class="menu">
            <template slot="title"><i class="el-icon-message"></i><span style="margin-left: 10px;">系统管理</span></template>

            <el-menu-item-group class="menu-item-group">
            <template slot="title">软件管理</template>
            <el-menu-item index="/">软件信息</el-menu-item>
            <el-menu-item index="user">用户信息</el-menu-item>
            <el-menu-item index="sort">分类管理</el-menu-item>
            <el-menu-item index="asmr">ASMR管理</el-menu-item>
            <el-menu-item index="lookonmelon">吃瓜趣事</el-menu-item>
            <el-menu-item index="task">任务配置</el-menu-item>
            <el-menu-item index="goods">套餐管理</el-menu-item>
            </el-menu-item-group>

            <el-menu-item-group title="开发者" class="menu-item-group">
            <el-menu-item index="developer">开发者管理</el-menu-item>
            </el-menu-item-group>

            <el-submenu index="1-4" class="menu">
            <template slot="title">选项4</template>
            <el-menu-item index="1-4-1" class="menu-item-group">选项4-1</el-menu-item>
            </el-submenu>
        </el-submenu>

        </el-menu>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如：import 《组件名称》 from '《组件路径》';
export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    isLogoTextShow: Boolean,
    isCollapse: Boolean
  },
  data() {
//这里存放数据
    return {
    };
  },
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {},
beforeCreate() { }, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() { },
beforeMount() { }, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() { },
beforeUpdate() { }, //生命周期 - 更新之前
updated() { }, //生命周期 - 更新之后
beforeDestroy() { }, //生命周期 - 销毁之前
destroyed() { }, //生命周期 - 销毁完成
activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
</style>