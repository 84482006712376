import Vue from 'vue'
import VueRouter from 'vue-router'
import User from '../views/User.vue'
import Login from '../views/Login.vue'

// 通过vue.use(插件)，安装插件
Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      //导入头部和菜单，NavMenu，Header
      component: () => import('../components/IndexFrame.vue'), 
      //重定向到user，不然啥页面也不显示，只能用户点击后才显示
      redirect: '/home', 
      meta: { 
        title: "首页",
        isAuthenticated: true
     },
      //子页面分类
      children: [
        {
        meta: { 
            title: "网站信息",
            isAuthenticated: true
         },
        path: 'home',
        // name: 'webInfo',
        component: () => import('../views/Home.vue')
        },
        {
          meta: { 
            title: "用户管理",
            isAuthenticated: true
         },
          path: 'user',
          component: User
        },
        {
          meta: { 
            title: "个人信息",
            isAuthenticated: true 
           },
          path: 'person',
          component: () => import('../views/Person.vue')
        },
        {
          meta: { 
            title: "分类管理",
            isAuthenticated: true 
           },
          path: 'sort',
          component: () => import('../views/Sort.vue')
        },
        {
          meta: { 
            title: "ASMR管理",
            isAuthenticated: true 
           },
          path: 'asmr',
          component: () => import('../views/Asmr.vue')
        },
        {
          meta: { 
            title: "吃瓜趣事",
            isAuthenticated: true 
           },
          path: 'lookonmelon',
          component: () => import('../views/LookOnMelon.vue')
        },
        {
          meta: { 
            title: "套餐管理",
            isAuthenticated: true 
           },
          path: 'goods',
          component: () => import('../views/Goods.vue')
        },
        {
          meta: { 
            title: "任务信息",
            isAuthenticated: true 
           },
          path: 'task',
          component: () => import('../views/Task.vue')
        },
        {
          meta: { 
            title: "开发者管理",
            isAuthenticated: true 
           },
          path: 'developer',
          component: () => import('../views/Developer.vue')
        },
      ]
    },
    {
      path: "/login",
      meta: {title: '登陆页面'},
      component: Login
    },
    //404页面
    {
      path: "*",
      meta: {title: '404页面'},
      component: () => import('@/views/404.vue')
    },
  ]


   // 配置URL和组价直接的映射关系
const router = new VueRouter({
    // history模式 
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })


  router.beforeEach((to,from,next)=>{
    // 书写具体逻辑
    // to: 即将要进入的路由
    // from：从哪个路由来的即当前导航正要离开的路由
    // next: 放行，进入管道中的下一个路由

    //判断页面是否需要权限才能访问
    if (to.meta.isAuthenticated) {
        if(localStorage.getItem('role') === 'admin') {
            next();
        }else {
            next('/404');
        }
    } else {
      next();
    }
 })
 

  
  // 将router对象传入到vue实例中
  export default router