<template>
  <div class='box'>
    <el-card class="card" shadow="hover">
      <h1>登录</h1>
      <el-form :model="Loginform" :rules="rules" ref="Loginform">
        <el-form-item prop="username">
          <el-input v-model="Loginform.username" prefix-icon="el-icon-user-solid" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="Loginform.password" prefix-icon="el-icon-key" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" style="width: 100%;" @click="Login('Loginform')" plain>登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如：import 《组件名称》 from '《组件路径》';
export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
//这里存放数据
    return {
      Loginform: {
        username: "",
        password: ""
      },
      rules: {
          username: [
            { required: true, message: '请输入账号', trigger: 'blur' },
            { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
          ]
        }
    };
  },
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  Login(loginform) {
    this.$refs[loginform].validate((valid) => {
      if (valid) {
        this.request.post("/sysUser", this.Loginform).then(result => {
          if (result.code == 200) {
              localStorage.setItem("role", result.data.username);
              localStorage.setItem("token", result.data.token);
              this.$router.push("/home")
              this.$message.success(result.message);
          } else {
              this.$message.error(result.message)
          }
        })
      } 
    });
  }
},
beforeCreate() { }, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() { },
beforeMount() { }, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() { },
beforeUpdate() { }, //生命周期 - 更新之前
updated() { }, //生命周期 - 更新之后
beforeDestroy() { }, //生命周期 - 销毁之前
destroyed() { }, //生命周期 - 销毁完成
activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>


<style>
.box {
  height: 100vh;
  background-image: linear-gradient(to bottom right, #FC466B, #3F5EFB);
  /* 隐藏超出的高度 */
  overflow: hidden;
}

.box .card {
  width: 380px;
  /* 设置上top=100 左右自适应 */
  margin: 260px auto;
}

h1 {
  text-align: center;
  margin-bottom: 30px;
}
</style>