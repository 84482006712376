<template>
  <div>
    <el-container style="height: 100vh; border: 1px solid #eee">

        <el-aside :width="elAsideWidth" class="menu">
            <Menu :isLogoTextShow="isLogoTextShow" :isCollapse="isCollapse"></Menu>
        </el-aside>
        
        <el-container>
            <el-header>
                <Header :openCollaspse="openCollaspse"></Header>
            </el-header>
            
            
            <el-main>
                <router-view/>
            </el-main>
        </el-container>

    </el-container>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如：import 《组件名称》 from '《组件路径》';
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'

export default {
//import引入的组件需要注入到对象中才能使用
  components: {
    Header,
    Menu
  },
  props: {},
  data() {
    //这里存放数据
    return {
        isCollapse: false,
        elAsideWidth: "200px",
        isLogoTextShow: true,
    };
  },
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    openCollaspse(){
        this.isCollapse = !this.isCollapse;
        if (this.isCollapse) {
            this.elAsideWidth = "60px";
            this.isLogoTextShow = false;
        } else {
            this.elAsideWidth = "200px";
            this.isLogoTextShow = false;
        }
    },
},
beforeCreate() { }, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() { },
beforeMount() { }, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() { },
beforeUpdate() { }, //生命周期 - 更新之前
updated() { }, //生命周期 - 更新之后
beforeDestroy() { }, //生命周期 - 销毁之前
destroyed() { }, //生命周期 - 销毁完成
activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>


<style>
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  }
</style>