import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
//引入并使用css样式
import './assets/globalStyle.css' 
//引入全局请类
import request from './utils/request'
//引入路由
import router from  '@/router/index'

//element-ui的全部组件
import ElementUI from 'element-ui'
//element-ui的css
import 'element-ui/lib/theme-chalk/index.css'
//使用elementUI
Vue.use(ElementUI) 

//设置request全局调用
Vue.prototype.request = request


//默认配置
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  //配置全局路由
  router: router
}).$mount('#app')
