<template>
  <div>

    <div>
      <el-input class="mn-top-bottom" placeholder="请输入用户名" size="small" style="width: 200px" prefix-icon="el-icon-user" v-model="username"></el-input>
      <el-input class="mn" placeholder="请输入手机号" size="small" style="width: 200px" prefix-icon="el-icon-phone" v-model="phone"></el-input>
      <el-button type="primary" size="small" @click="SearchUser">搜索</el-button>
      <el-button type="warning" size="small" @click="loadUsersData">重置</el-button>
      <el-button type="success" size="small" @click="newUsers">新增</el-button>
    </div>

    <el-table
      :data="tableUsersData"
      border
      style="width: 100%">
      <el-table-column
        fixed
        prop="id"
        label="ID"
        width="80">
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户账号">
      </el-table-column>
      <el-table-column
        prop="nickname"
        label="用户昵称">
      </el-table-column>
      <el-table-column
        prop="phone"
        label="手机号">
      </el-table-column>
      <el-table-column
        prop="sex"
        label="性别"
        width="70">
      </el-table-column>
      <el-table-column
        prop="birthday"
        label="生日"
        width="100">
      </el-table-column>
      <el-table-column
        prop="brief"
        label="用户签名">
      </el-table-column>
      <el-table-column
        prop="vipTime"
        label="会员时间"
        width="170">
      </el-table-column>
      <el-table-column
        prop="networkInfo"
        label="网络信息">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="注册时间"
        width="170">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="90">
        <template slot-scope="scope">
          <el-button @click="UserEdit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button type="text" size="small" @click="DeleteUser(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="tableUserSizeChange"
      @current-change="tableUserCurrentChange"
      :current-page="pageNum"
      :page-sizes="[30, 50, 80, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      class="mn-top-bottom"
      :total="tableTotal">
    </el-pagination>


    <el-dialog title="用户信息" :visible.sync="dialogUserEditFormVisible" :close-on-click-modal="false">
      <el-form :model="UserEditform">
        <el-form-item label="用户账号">
          <el-input v-model="UserEditform.username" autocomplete="off" :disabled="usernameDisable" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item label="用户密码">
          <el-input v-model="UserEditform.password" autocomplete="off" prefix-icon="el-icon-user" placeholder="用户密码，不修改请留空"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input v-model="UserEditform.nickname" autocomplete="off"  prefix-icon="el-icon-price-tag"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="UserEditform.phone" autocomplete="off"  prefix-icon="el-icon-phone"></el-input>
        </el-form-item>
        <el-form-item label="用户性别">
          <br>
          <el-select v-model="UserEditform.sex" placeholder="请选择性别">
            <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户生日">
          <br>
          <el-date-picker v-model="UserEditform.birthday" type="date" value-format="yyyy-MM-dd" placeholder="选择用户生日日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="用户签名">
          <el-input v-model="UserEditform.brief" autocomplete="off" prefix-icon="el-icon-edit"></el-input>
        </el-form-item>
        <el-form-item label="用户会员">
          <br>
          <el-date-picker v-model="UserEditform.vipTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会员到期日期时间" style="margin-right: 10px;"></el-date-picker>
          <el-button class="mn-left-right" type="primary" plain @click="plusTime(3)">+3天</el-button>
          <el-button class="mn-left-right" type="primary" plain @click="plusTime(7)">+7天</el-button>
          <el-button class="mn-left-right" type="primary" plain @click="plusTime(30)">+30天</el-button>
          <el-button class="mn-left-right" type="primary" plain @click="plusTime(99999)">+99999天</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUserEditFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUserEditform">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如：import 《组件名称》 from '《组件路径》';
import {formatDate} from '../utils/utils'
export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
//这里存放数据
    return {
      //用户表
      tableUsersData: [],
      //数据总条数
      tableTotal: 0,
      //当前分页
      pageNum: 1,
      //每个分页展示数据
      pageSize: 30,
      //用户昵称 用来搜索
      username: "",
      //用户手机号 用来搜索
      phone: "",
      //用户弹窗表单
      UserEditform: {},
      //用户弹窗是否显示
      dialogUserEditFormVisible: false,
      sexList: [
        {"lable": "男", "value": "男"},
        {"lable": "女", "value": "女"},
        {"lable": "其它", "value": "其它"}
      ],
      //编辑框是否可编辑
      usernameDisable: true,
    };
  },
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  //用户数据编辑
  UserEdit(row){
    //赋值给表单
    this.UserEditform = row;
    this.UserEditform.password = null;
    //编辑框是否可编辑
    this.usernameDisable = true;
    //显示dialog
    this.dialogUserEditFormVisible = true;
  },
  //加载用户数据
  loadUsersData(){
    this.request.get("/user/allUsers", {
      params: {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
    }).then(result => {
      if (result.code === 200) {
        this.tableUsersData = result.data.appUserList;
        this.tableTotal = result.data.total;
      } else {
        this.$message.error("获取数据失败!")
      }
    })
  },
  //当前分页监听器
  tableUserCurrentChange(current){
    this.pageNum = current;
    this.loadUsersData();
  },
  //当前分页显示条数监听器
  tableUserSizeChange(current){
    this.pageSize = current;
    this.loadUsersData();
  },
  //搜索事件
  SearchUser(){
    this.request.get("/user/allUsers", {
      params: {
        username: this.username,
        phone: this.phone,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
    }).then(result => {
      if (result.code === 200) {
        this.tableUsersData = result.data.appUserList;
        this.tableTotal = result.data.total;
      } else {
        this.$message.error(result.message)
      }
    })
  },
  //编辑用户保存事件
  saveUserEditform(){
    this.request.post("/user/sys/updateInfo", this.UserEditform).then(result => {
      if (result.code === 200) {
        this.loadUsersData();
        this.dialogUserEditFormVisible = false;
        this.$message.success(result.message)
      } else {
        this.$message.error(result.message)
      }
    })
  },
  //加时间按钮
  plusTime(time){
    //当前时间戳
    let currentTime = new Date().getTime() / 1000;
    //会员时间转时间戳
    let vipTimes = new Date(this.UserEditform.vipTime).getTime() / 1000;
    //一天的毫秒
    let oneDay = (24 * 60 * 60);
    //判断会员时间是否大于当前时间
    if (vipTimes > currentTime) {
      //会员时间大于当前时间，使用会员到期时间 + 指定天数
      this.UserEditform.vipTime = formatDate(vipTimes + (oneDay * time));
    } else {
      //会员时间小于当前时间，用当前时间 + 指定天数
      this.UserEditform.vipTime = formatDate(currentTime + (oneDay * time));
    }
  },
  //删除用户
  DeleteUser(id){
    this.request.delete(`/user/sys/delete/${id}`).then(result => {
      if (result.code === 200) {
        this.loadUsersData();
        this.$message.success(result.message)
      } else {
        this.$message.error(result.message)
      }
    })
  },
  newUsers(){
    //清空表单
    this.UserEditform = {}
    //编辑框是否可编辑
    this.usernameDisable = false;
    this.dialogUserEditFormVisible = true;
  }
},
beforeCreate() { }, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {
  this.loadUsersData();
 },
beforeMount() { }, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() { },
beforeUpdate() { }, //生命周期 - 更新之前
updated() { }, //生命周期 - 更新之后
beforeDestroy() { }, //生命周期 - 销毁之前
destroyed() { }, //生命周期 - 销毁完成
activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
</style>